import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const PackagesHero = () => {
  return (
    <header id="packagesHero#packagesHero" className="background_gradieant_color flex justify-center mb-[39px] lg:mb-[123px] items-center overflow-hidden relative h-[330px] mt-12 lg:mt-20 w-full">
      <StaticImage
      placeholder="none"
        src="../../static/svgs/package-decoration-symbol.svg"
        alt="bg-img"
        className="!absolute left-[-230px] sm:left-[10%]"
      />
      <h1 className="font-bold text-[40px] leading-[49px] lg:text-[42px] w-[400px] text-center text-gray_50 lg:leading-[51px] fontFamily2">
        Holistic Healing & Recovery Options
      </h1>
    </header>
  );
};

export default PackagesHero;
