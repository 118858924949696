import React from "react";

const ServicesList = ({toggleFunc}) => {
  return (
    <section>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Custom Therapeutic Massage
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
            Our Custom Therapeutic Massage offers a comprehensive and personalized experience
            that combines a variety of techniques to address your specific needs. This result-driven
            approach targets your conditions, relieves pain, and promotes healing.
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Custom Therapeutic Massage</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>30-Min Bodywork</td>
                    <td>$90</td>
                </tr>
                <tr>
                    <td>45-Min Bodywork</td>
                    <td>$115</td>
                </tr>
                <tr>
                    <td>60-Min Bodywork</td>
                    <td>$145</td>
                </tr>
                <tr>
                    <td>75-Min Bodywork</td>
                    <td>$170</td>
                </tr>
                <tr>
                    <td>90-Min Bodywork</td>
                    <td>$195</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Acupuncture
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                Acupuncture offers a dual advantage for pain management, delivering both immediate
                relief and lasting benefits. Supported by thorough research, this practice emphasizes
                proactive and corrective internal healing for both short-term and chronic pain.
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Acupuncture</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>90-Min Initial Visit</td>
                    <td>$245</td>
                </tr>
                <tr>
                    <td>60-Min Follow-up Visit</td>
                    <td>$170</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Cupping therapy
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                Cupping therapy is an ancient form of alternative medicine in which a therapist puts
                special cups on the skin to create suction to promote blood flow and relaxation and assist
                in decreasing pain and inflammation.
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Cupping Therapy</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>15-Min</td>
                    <td>$55</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                SportStretch Therapy
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                SportStretch is a therapist-assisted stretch performed on a mat or table. Each session is
                customized to your needs and includes various modalities and techniques. Stretching can
                help you stay injury-free via 2 main mechanisms: increase ROM + blood flow.
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>SportStretch Therapy</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>30-Min</td>
                    <td>$75</td>
                </tr>
                <tr>
                    <td>60-Min</td>
                    <td>$125</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Whole Body Cryotherapy
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                Cryotherapy is a therapeutic application of extremely cold dry air, between −130°F and
                −184°F to stimulate the body's natural healing abilities. Our Cryo chamber is designed to
                deliver the easiest, safest and the most effective Cryo treatments in the industry.
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Whole Body Cryotherapy</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>15-Min</td>
                    <td>$75</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Infrared Sauna
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                Infrared sauna increases the body’s core temperature thus resulting in a much deeper,
                more detoxifying sweat from the cellular level of the skin where many toxins are housed.
                Drink at least eight oz. of water prior to your session.
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Infrared Sauna</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>50-Min</td>
                    <td>$45</td>
                </tr>
            </table>
          </div>
        </div>
        <div className="w-[90%] mx-auto lg:w-4/5 gap-8 mb-[58px] lg:mb-[73px] lg:gap-20 flex flex-col lg:flex-row lg:items-center">
          <div className="w-full lg:w-2/4">
            <h3 className="text-3xl leading-[37px] font-semibold text-gray_900 fontFamily2 mb-6">
                Compression Therapy
            </h3>
            <p className="deskfontFamily1 font-normal text-base leading-7 text-typography_body mb-6">
                Increase blood flow to tired muscles, reduce inflammation, and flush out unwanted toxins
                with pneumatic compression therapy using recovery boots. Relax as the graduated
                pressure does its work up and down your legs.
            </p>
          </div>
          <div className="w-full lg:w-2/4 flex justify-center">
            <table className="w-full h-full text-left">
                <tr>
                    <th>Compression Therapy</th>
                    <th className="w-1/4">Rate</th>
                </tr>
                <tr>
                    <td>45-Min</td>
                    <td>$40</td>
                </tr>
            </table>
          </div>
        </div>
    </section>
  );
};

export default ServicesList;
