import classNames from "classnames";
import React, { useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import Button from "../Button/index";
import PackagesCards from "../PackagesCards";
import { useLocation } from "@reach/router";

const BODYWORK_PACKAGES = [
  {
    name: "ACUPUNCTURE",
    price: 775,
    quantity: 5,
    header: ["Acupuncture and Bodywork"],
    points: [
      "60-minute custom therapeutic massage sessions",
      "Acupuncture, Tui-na, Cupping, Gua-sha, and Electrical Stimulation",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3042003&pId=400656"
  },
  {
    name: "THERAPEUTIC MASSAGE I",
    price: 420,
    quantity: 3,
    header: ["Custom Therapeutic Massage"],
    points: [
        "60-minute custom therapeutic massage sessions",
        "*Cupping not included"
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3050748&pId=400656"
  },
  {
    name: "THERAPEUTIC MASSAGE II",
    price: 555,
    quantity: 3,
    header: ["Custom Therapeutic Massage"],
    points: [
        "90-minute custom therapeutic massage sessions",
        "*Cupping not included"
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3050752&pId=400656"
  },

];

const SPECIAL_PACKAGES = [
   /* {
    name: "EXCLUSIVE INTRO OFFER",
    price: 99,
    quantity: "UNLIMTED",
    header: ["7 Day Unlimited Membership", "Redeem on 3 services"],
    points: [
        "Infrared Sauna ",
        "Whole Body Cryotherapy",
        "Compression Therapy",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3007726&pId=400656"
  },*/
 /* {
    name: "SOCIALs SATURDAYS | DAY PASS",
    price: 80,
    quantity: 3,
    header: ["50% off Core Amenity Packs"],
    points: [
        "Infrared Sauna",
        "Whole Body Cryotherapy",
        "Compression Therapy",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/services?lid=429303&oiid=p%3A432967&pId=400656"
  },*/

];

const MULTI_PACKAGES = [
  {
    name: "MULTI-PACKS | 10",
    price: 285,
    perSession: 28,
    quantity: 10,
    header: ["Core Amenity services"],
    points: [
      "Whole Body Cryotherapy Sessions + Compression Therapy + Infrared Sauna",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3005281&pId=400656"
  },
  {
    name: "MULTI-PACKS | 15",
    price: 389,
    perSession: 25,
    quantity: 15,
    header:
      ["Core Amenity services"],
    points: [
      "Whole Body Cryotherapy Sessions + Compression Therapy + Infrared Sauna",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3047769&pId=400656"
  },
  {
    name: "MULTI-PACKS | 20",
    price: 435,
    perSession: 21,
    quantity: 20,
    header:
      ["Core Amenity services"],
    points: [
      "Whole Body Cryotherapy Sessions + Compression Therapy + Infrared Sauna",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3015589&pId=400656"
  },
  
];
const WELLNESS_PACKAGES = [
  {
    name: "Stretch Zone",
    price: 635,
    quantity: 5,
    header: ["SportStretch and Core Amenity services"],
    points: [
      "60-minute SportStretch Therapy sessions",
      "2 amenities: Infrared Sauna and/or Compression Therapy ",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3008081&pId=400656",
  },
  {
    name: "Signature",
    price: 735,
    quantity: 5,
    header: ["Custom Therapeutic Massage and Core Amenity services"],
    points: [
      "60-minute Therapeutic Massage sessions",
      "3 amenities: Infrared Sauna and/or Compression Therapy ",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3008078&pId=400656",
  },
  {
    name: "Premium",
    price: 975,
    quantity: 5,
    header: ["Custom Therapeutic Massage and Core Amenity services (*longer treatment session and access to Cryotherapy)"],
    points: [
      "90-minute Therapeutic Massage sessions",
      "3 amenities: Cryotherapy, Infrared Sauna and/or Compression Therapy ",
    ],
    bookNow: "https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?id=3019530&pId=400656",
  },
 
];

const PackagesSection = ({ toggleFunc }) => {
  const location = useLocation();
  const [toggleState, setToggleState] = useState("bodywork");
  const [packageData, setPackageData] = useState([]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    autoplay: true,
    slide: ".slick-slideshow__slide",
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerPadding: "5px",
          slidesToShow: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 390,
        settings: {
          centerMode: false,
          centerPadding: "0",
          slidesToShow: 1,
          infinite: false,
          dots: true,
        },
      },
    ],
  };

  const packageMap = {
    bodywork: BODYWORK_PACKAGES,
    wellness: WELLNESS_PACKAGES,
    multi: MULTI_PACKAGES,
    special: SPECIAL_PACKAGES,
  };

  useEffect(() => {
    setPackageData(packageMap[toggleState]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleState]);

  useEffect(() => {
    if (location.hash) {
      setToggleState(location.hash.slice(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);

  return (
    <section
      id="explore-packages"
      className="w-4/5 mx-auto mb-[90px] lg:mb-[128px]"
    >
      <div className="w-full flex flex-col items-center gap-8">
        <h3 className="font-semibold text-3xl leading-[37px] text-gray_900 fontFamily2">
          Therapy Packages
        </h3>
        <div className="flex  flex-col sm:flex-row items-center gap-4 md:gap-6 ">
          <Button
            id="bodywork"
            texts={"BODYWORK PACKS"}
            onClick={() => toggleTab("bodywork")}
            customClass={
              toggleState === "bodywork"
                ? classNames("font-semibold text-lg leading-6 fontFamily2", [
                    "text-primary_green",
                  ])
                : "text-[#B3B3B3] font-semibold text-lg leading-6  fontFamily2"
            }
          />
          <Button
            id="wellness"
            onClick={() => toggleTab("wellness")}
            texts={"WELLNESS PACKS"}
            customClass={
              toggleState === "wellness"
                ? classNames("font-semibold text-lg leading-6 fontFamily2", [
                    "text-primary_green",
                  ])
                : "text-[#B3B3B3] font-semibold text-lg leading-6 fontFamily2"
            }
          />
          <Button
            id="multi"
            onClick={() => toggleTab("multi")}
            texts={"CORE AMENITY PACKS"}
            customClass={
              toggleState === "multi"
                ? classNames("font-semibold text-lg leading-6 fontFamily2", [
                    "text-primary_green",
                  ])
                : "text-[#B3B3B3] font-semibold text-lg leading-6 fontFamily2"
            }
          />

        <Button
            id="special"
            onClick={() => toggleTab("special")}
            texts={"SPECIALS"}
            customClass={
              toggleState === "special"
                ? classNames("font-semibold text-lg leading-6 fontFamily2", [
                    "text-primary_green",
                  ])
                : "text-[#B3B3B3] font-semibold text-lg leading-6 fontFamily2"
            }
          />
        </div>
      </div>
      <div>
        <div className="lg:grid lg:grid-cols-2 xl:grid-cols-3 hidden gap-10 mt-8">
          <PackagesCards data={packageData} />
        </div>
        <div className="packages_section_slider mt-8 block lg:hidden">
          {packageData.length > 0 && (
            <Slider {...settings}>
              {packageData.map((data) => {
                return (
                  <div key={data.name} className="slick-slideshow__slide">
                    <div className="bg-gray_50 pricecard_box_shadow mr-auto slider_card_width_adjust pb-[49px] rounded-b-lg">
                      <div className="w-full flex flex-col pl-3  bg-gray_500 h-[172px] rounded-lg ">
                        <h4 className="font-semibold text-2xl leading-[37px] text-white fontFamily2 mt-9 mb-6">
                          {data.name}
                        </h4>
                        <h5 className=" text-white text-xl leading-[27px] font-semibold fontFamily2">
                        ${data.price} {data.perSession && (
                            <span className="text-sm"> (${data.perSession}/session)</span>
                        
                        )}
                        </h5>
                      </div>
                      <div className="px-4">
                        <p className="mt-6 uppercase mb-6 font-semibold text-purple_400 flex items-center justify-center h-7 px-4 w-fit bg-purple_100 rounded-2xl fontFamily2 text-sm leading-[18px] ">
                          {data.quantity} Pack
                        </p>
                        <p className="mb-12 text-base leading-7 font-normal fontFamily1 relative text-typography_body before:content-'' before:w-[123px] before:h-[2px] before:rounded-sm before:bg-purple_400 before:absolute before:left-0 before:bottom-[-24px] ">
                          {data.header[0]} <br />
                          {data.header[1]}
                        </p>
                        <ul
                          className={classNames(
                            "2xl:w-3/4",
                            data.points.length < 2
                              ? "list-none"
                              : "list-disc pl-6 "
                          )}
                        >
                          {data.points?.map((items) => {
                            return (
                              <li
                                key={items}
                                className="font-semibold text-base text-typography_body fontFamily1"
                              >
                                {items}
                              </li>
                            );
                          })}
                        </ul>
                        <a href={data.bookNow}>
                            <Button
                            texts={"Book Now"}
                            customClass={
                                "uppercase text-sm font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em]  w-[8rem] rounded-sm h-10 transition hover:bg-green_400 hover:text-purple mt-4"
                            }
                            />
                        </a>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
        <div className="mt-[60px] gap-[10px] md:gap-8 flex  flex-row justify-center">
          <Button
            onClick={() => toggleFunc("contact")}
            texts={"CONTACT"}
            customClass={
              "uppercase text-base font-semibold text-primary_green fontFamily2 w-full w-[168px] tracking-[0.03em] rounded-sm border-primary_green border-[1px] h-12 transition hover:bg-[#f1f1f1]"
            }
          />
          <a href="https://www.fresha.com/book-now/cre8align-hkb-rqzrkskr/paid-plans?pId=400656">
            <Button
              texts={"Book Now"}
              customClass={
                "uppercase text-base font-semibold text-white bg-primary_green fontFamily2 tracking-[0.03em]  w-[165px] rounded-sm h-12 transition hover:bg-green_400 hover:text-purple"
              }
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default PackagesSection;
